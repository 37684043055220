import {
  GeoJsonFeatureCollection,
  GeoJsonType,
  LayerFeature,
} from "kaminow-shared";

/**
 * IMAGES
 */

export const breakPoints = [
  { key: "sm", value: 600 },
  { key: "md", value: 900 },
  { key: "lg", value: 1280 },
  { key: "xl", value: 1920 },
  { key: "xxl", value: 2560 },
];

/**
 * TOASTS
 */

export const defaultToastParams: any = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

/**
 * LAYER PRIORITIES
 */

export const baseMapPriority = 1;
export const mapLayerPriority = 2;
export const linesLayerPriority = 3;
export const searchLayerPriority = 4;
export const pointsLayerPriority = 5;
export const iconsLayerPriority = 6;
export const userMapPointsPriority = 7;
export const editPathPriority = 8;
export const selectedPathPriority = 9;

/**
 * EMPTY GEOJSON
 */

export const emptyFeatureCollection: GeoJsonFeatureCollection<LayerFeature> = {
  type: GeoJsonType.FeatureCollection,
  features: [],
};

/**
 * GEOJON STYLING
 */
export const defaultTrackColor = "#0c6fb1";
export const defaultPointColor = "#0c6fb1";
export const defaultSearchPointColor = "#0692FF";

/**
 * INTERACTIONS
 */

export const DEBOUNCE_TIME = 1000;

export const IS_CLICK_DISTANCE_FINE_TOLERANCE = 4;
export const IS_CLICK_DISTANCE_GROSS_TOLERANCE = 12;
export const IS_CLICK_TIME_INTERVAL = 500;

export const FEATURES_AT_DISTANCE_BUFFER = 5;

export const DOUBLE_CLICK_INTERVAL = 500;

export const INSTANT_DATA_PROPAGATION = false;

/**
 * OTHER
 */

export const appName = "Kaminow";

/**
 * CHARTS
 */

export enum FillType {
  Surface = "surface",
  Waytypes = "waytypes",
  Steepness = "steepness",
}

export const fillTypes = Object.values(FillType);

export enum OtherChartOption {
  None = "none",
  Steps = "steps",
}

export const ChartOption = { ...FillType, ...OtherChartOption };
export type ChartOption = FillType | OtherChartOption;

import * as Sentry from "@sentry/react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { ActionPackedAdventurePermission } from "kaminow-shared";
import { atom, atomFamily, selector } from "recoil";
import { AuthUser } from "../types/auth.types";

export const userState = atom<AuthUser | null | undefined>({
  key: "user",
  default: undefined,
  effects: [
    ({ setSelf }) => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setSelf({ id: user.uid, email: user.email });
          Sentry.setUser({ id: user.uid, email: user.email || undefined });
        } else {
          setSelf(null);
          Sentry.setUser(null);
        }
      });
    },
  ],
});

export const isSignedInState = selector({
  key: "isSignedIn",
  get: ({ get }) => {
    const user = get(userState);
    if (user === undefined) return undefined;
    return !!user;
  },
});

export const permissionsState = atomFamily<
  ActionPackedAdventurePermission[],
  string
>({
  key: "permissions",
  default: [],
  effects: (adventureId: string) => [
    ({ setSelf, onSet }) => {
      if (!adventureId) return;

      const db = getFirestore();
      const q = query(collection(db, `adventures/${adventureId}/permissions`));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const permissions: ActionPackedAdventurePermission[] = [];
        querySnapshot.forEach((doc: any) => {
          permissions.push({ ...doc.data(), id: doc.id });
        });
        setSelf(permissions);
      });

      onSet((_newValue, _oldValue, isReset) => {
        if (isReset) {
          unsubscribe();
        }
      });
      return () => {
        unsubscribe();
      };
    },
  ],
});

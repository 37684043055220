import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { languages } from "../../i18n/config";

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();

  return (
    <Box sx={{ display: "flex", padding: "6px 16px", alignItems: "center" }}>
      <Typography>{`${t("nav.language")}: `}</Typography>
      <ButtonGroup variant="text">
        {(Object.keys(languages) as Array<keyof typeof languages>).map(
          (lng) => (
            <Button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              onClick={() => i18n.changeLanguage(lng)}
            >
              {languages[lng].name}
            </Button>
          )
        )}
      </ButtonGroup>
    </Box>
  );
};

import { captureFeedback, feedbackIntegration } from "@sentry/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/auth.state";

type Tags = { [key: string]: string | number | boolean };
type Params = { tags?: Tags };
export const useFeedback = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);

  const sendFeedbackWithUI = useCallback(
    async (params?: Params) => {
      const tags: Tags = { ...(params?.tags || {}), form: true };

      const feedback = feedbackIntegration({
        autoInject: false,
        showName: false,
        showEmail: false,
      });

      const form = await feedback.createForm({
        formTitle: t("feedback.formTitle"),
        submitButtonLabel: t("feedback.submitButtonLabel"),
        cancelButtonLabel: t("feedback.cancelButtonLabel"),
        confirmButtonLabel: t("feedback.confirmButtonLabel"),
        addScreenshotButtonLabel: t("feedback.addScreenshotButtonLabel"),
        removeScreenshotButtonLabel: t("feedback.removeScreenshotButtonLabel"),
        messageLabel: t("feedback.messageLabel"),
        isRequiredLabel: t("feedback.isRequiredLabel"),
        messagePlaceholder: t("feedback.messagePlaceholder"),
        tags,
      });
      form.appendToDom();
      form.open();
    },
    [t]
  );

  const sendFeedback = useCallback(
    async (message: string, params?: Params) => {
      const tags: Tags = { ...(params?.tags || {}), form: false };
      if (user?.email) {
        tags["user.email"] = user.email;
      }

      captureFeedback(
        { message },
        {
          captureContext: {
            tags,
            user: user
              ? { id: user.id, email: user.email || undefined }
              : undefined,
          },
        }
      );
    },
    [user]
  );

  return { sendFeedbackWithUI, sendFeedback };
};

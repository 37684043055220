const env = import.meta.env;

const emptyStyle = "mapbox/empty-v8";
const outdoorsStyle = "louiseugene/cl3iiqumm000215qmvuldmxmd";
const satelliteStyle = "louiseugene/cl7gd406e001f15p8qm3i4w46";
// mapbox/outdoors-v11
// mapbox/satellite-v9
export const mapboxConfig = {
  styles: { emptyStyle, outdoorsStyle, satelliteStyle },
  token: env.VITE_MAPBOX_TOKEN,
};

export const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_API_KEY,
  authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.VITE_FIREBASE_DATABASE_URL,
  projectId: env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.VITE_FIREBASE_APP_ID,
  measurementId: env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const openCycleApiKey = env.VITE_OPEN_CYCLE_API_KEY;

export const sentryConfig = {
  dsn: env.VITE_SENTRY_DSN,
};

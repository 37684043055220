import { Helmet } from "react-helmet";
import { OptimizedImage } from "./image/OptimizedImage";
import { ContextualNav } from "./nav/ContextualNav";

export const HomePage = () => {
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>Kaminow</title>
        <meta
          name="description"
          content="Plan your next biking or hiking adventure with Kaminow!"
        />
      </Helmet>
      <div
        style={{
          color: "white",
          textShadow: "1px 1px 6px rgba(0,0,0,0.2)",
          fontSize: 60,
          padding: "70px",
          paddingBottom: "20px",
          zIndex: 1,
        }}
      >
        Kaminow
      </div>

      <ContextualNav />

      <OptimizedImage
        src="home-bg.jpg"
        ratio={3 / 2}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        alt="A snowy mountain landscape with a distant peak, evergreen trees, and two people hiking up a slope under a blue sky."
      />
    </div>
  );
};

import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useIsMinimized } from "../../hooks/useIsMinimized";
import { PATHS } from "../../paths";
import { MyAdventuresNav } from "./MyAdventuresNav";
import { UserNav } from "./UserNav";

export const ContextualNav = () => {
  const location = useLocation();
  const isMinimized = useIsMinimized();

  const isHomePage = PATHS["home"].path === location.pathname;
  const isMyAdventuresPage = new RegExp(
    PATHS["user_adventures"].constructor("([a-zA-Z]|[0-9])*")
  ).test(location.pathname);

  const sideNav = isMinimized && !isHomePage;

  return (
    <Box
      sx={{
        top: sideNav ? null : "26px",
        bottom: sideNav ? "268px" : null,
        right: sideNav ? "5px" : "10px",
        position: "absolute",
        zIndex: 6,
        display: "flex",
        flexDirection: sideNav ? "column-reverse" : "row-reverse",
        rowGap: "5px",
        columnGap: "5px",
      }}
    >
      <UserNav />
      {!isMyAdventuresPage && <MyAdventuresNav />}
    </Box>
  );
};

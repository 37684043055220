import { breakPoints } from "../../constants";

const defaultRatio = 3 / 2;

type OptimizedImageProps = {
  src: string;
  ratio?: number;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const OptimizedImage = (props: OptimizedImageProps) => {
  const { src, ratio = defaultRatio, ...other } = props;

  return (
    <picture {...other}>
      {breakPoints.map(({ key, value }) => (
        <source
          key={`${key}-landscape`}
          srcSet={`/image/${src}?size=${key}&format=landscape, /image/${src}?size=${key}&format=landscape&resolution=2 2x`}
          media={`(max-width: ${value}px) and (min-aspect-ratio: ${ratio})`}
        />
      ))}
      {breakPoints.map(({ key, value }) => (
        <source
          key={`${key}-portrait`}
          srcSet={`/image/${src}?size=${key}&format=portrait, /image/${src}?size=${key}&format=portrait&resolution=2 2x`}
          media={`(max-height: ${value}px) and (max-aspect-ratio: ${
            1 / ratio
          })`}
        />
      ))}
      {breakPoints.map(({ key, value }) => (
        <source
          key={`${key}-square`}
          srcSet={`/image/${src}?size=${key}&format=square, /image/${src}?size=${key}&format=square&resolution=2 2x`}
          media={`(max-width: ${value}px) and (max-height: ${value}px)`}
        />
      ))}
      <img src={`/image/${src}?size=xxl`} {...other} />
    </picture>
  );
};

import { ViewCarouselOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PATHS } from "../../paths";
import { userState } from "../../state/auth.state";

export const MyAdventuresNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useRecoilValue(userState);
  const userId = user?.id;

  const goToUserAdventures = useCallback(async () => {
    navigate(PATHS["user_adventures"].constructor(userId));
  }, [userId, navigate]);

  if (!userId) return null;

  return (
    <Tooltip title={`${t("nav.my-adventures")}`}>
      <IconButton
        sx={{
          color: "primary.contrastText",
          bgcolor: "primary.main",
          "&:hover": { bgcolor: "primary.dark" },
        }}
        onClick={goToUserAdventures}
      >
        <ViewCarouselOutlined />
      </IconButton>
    </Tooltip>
  );
};
